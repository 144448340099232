import actions from "./actions";
import mutations from "./mutations";

const state = {
    isRedeeming: false,
    didRedeem: null,
    isTxConfirmed: false,
    txEtherscanUrl: "",
    redemptionErrorMessage: null,
    redeemedTokens: [
        // {
        //     "name": "FVCKAVATAR// #82",
        //     "created_by": "FVCKRENDER",
        //     "description": "Revealing...",
        //     "image": "https://res.cloudinary.com/manifoldxyz/image/upload/v1648525106/FCbKKGUVEAE4t9H_s0jwuk.jpg",
        //     "attributes": [
        //       {
        //         "trait_type": "Artist",
        //         "value": "JohnnyBoi"
        //       },
        //       {
        //         "trait_type": "avatar",
        //         "value": true
        //       }
        //     ]
        //   }
    ],
    isRedemptionEnabled: false,
};

const store = {
    namespaced: true,
    state,
    actions,
    mutations,
};

export default store;
