<template>
    <div class="o-container || o-padding -big-top -big-bottom" ref="lazyLoad">
        <Grid :modifiers="['-col-4', '-col-12@from-medium', '-gutter']">
            <!-- Not authenticated -->
            <GridItem
                v-if="!isAuthenticated"
                :col-start-medium="4"
                :col-end-medium="8"
            >
                <div class="o-padding -big-top">
                    <Wysiwyg :modifiers="['-outline']">
                        <p>Please authenticate by connecting a wallet.</p>
                    </Wysiwyg>
                </div>
            </GridItem>
            <!-- ./Not authenticated -->

            <!-- Authenticated -->
            <GridItem
                :col-start-medium="4"
                :col-end-medium="13"
                v-else
            >
                <!-- Redeemed -->
                <template v-if="redeemedTokens.length > 0">
                    <div class="c-gallery_box">
                        <Label tag="h2"> Congratulations! You just redeemed {{redeemedTokens.length}} fvck_avatar(s)</Label>

                        <a class="c-link" :href="txEtherscanUrl" target="_blank" rel="noopener">
                            View transaction on etherscan
                        </a>

                        <div class="c-gallery -inventory || o-padding -medium-top -medium-bottom">
                            <div class="c-gallery_item" v-for="(item, index) in redeemedTokens" :key="index" data-lazy-load-wrapper>
                                <div class="c-gallery_item_inner -square">
                                    <img class="c-gallery_item_image" :data-src="(item.image != null) ? item.image : 'assets/images/avatar-placeholder.jpg'" alt="FVCK_AVATAR" data-lazy-load>
                                </div>
                                <Label tag="p" class="c-gallery_legend -inventory">
                                    {{item.name ? item.name : 'Revealing...'}}
                                </Label>
                                <div class="c-gallery_action">
                                    <ul class="c-gallery_action_list">
                                        <li class="c-gallery_action_item" v-if="item.contractAddress && item.tokenId">
                                            <a :href="`https://opensea.io/assets/${item.contractAddress}/${item.tokenId}`" target="_blank" rel="noopener noreferrer" title="View on Opensea" class="c-gallery_action_link">
                                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10,0C-3-0.2-3,20.2,10,20C23,20.2,23-0.2,10,0z M4.9,10.3c0.2,0,2.6-4.4,2.8-4.1c0.7,1.6,1.1,3,0,4.2
                                                    C7.6,10.4,4.8,10.7,4.9,10.3z M16.5,11.7c-1.5,0.5-2.1,3.3-3.8,3.4c-2.7-0.1-8.7,1-8.6-3.4c-0.2-0.3,3-0.1,3-0.2
                                                    c0.1,1.4,1.4,1.3,2.6,1.2v-1.1c-0.1-0.1-1.7,0.2-1.5-0.1c0.7-0.9,1.3-2.1,1.1-3.4C9.2,7,8.6,6,8.2,4.9c0.1-0.2,1.3,0.3,1.5,0.3
                                                    c-0.2-2,1.5-1.3,1.1,0.3c0,0,0.1,0,0.1,0c1.5,1.1,3.4,3.1,1.8,4.9c-0.5,0.7-1,1.3-2,1.2v1.1c1.1,0.1,1.7,0,2.5-0.9c0,0,3-0.9,3-0.9
                                                    C16.7,10.9,16.5,11.7,16.5,11.7L16.5,11.7z"/>
                                                </svg>
                                            </a>
                                        </li>
                                        <li class="c-gallery_action_item" v-if="item.contractAddress && item.tokenId">
                                            <a :href="`https://looksrare.org/collections/${item.contractAddress}/${item.tokenId}`" target="_blank" rel="noopener noreferrer" title="View on Looksrare" class="c-gallery_action_link">
                                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.9375 7.5625L5.5 9L6.9375 10.4375C8.625 12.125 11.375 12.125 13.0625 10.4375L14.5 9L13.0625 7.5625C11.375 5.875 8.625 5.875 6.9375 7.5625ZM12.0625 9C12.0625 10.1458 11.125 11.0625 10 11.0625C8.85417 11.0625 7.9375 10.125 7.9375 9C7.9375 7.85417 8.875 6.9375 10 6.9375C11.1458 6.9375 12.0625 7.85417 12.0625 9Z"/>
                                                    <path d="M10.0013 9.91667C10.5013 9.91667 10.8971 9.52083 10.8971 9.02083C10.8971 8.52083 10.5013 8.125 10.0013 8.125C9.5013 8.125 9.10547 8.52083 9.10547 9.02083C9.10547 9.5 9.5013 9.91667 10.0013 9.91667Z"/>
                                                    <path d="M10 0C4.47917 0 0 4.47917 0 10C0 15.5208 4.47917 20 10 20C15.5208 20 20 15.5208 20 10C20 4.47917 15.5208 0 10 0ZM10 15.6667L3.33333 9L7.29167 5.04167H12.7083L16.6667 9L10 15.6667Z"/>
                                                </svg>
                                            </a>
                                        </li>
                                        <li class="c-gallery_action_item">
                                            <a :href="item.image" download target="_blank" rel="noopener noreferrer" title="Download" class="c-gallery_action_link" v-if="item.image">
                                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10c5.5,0,10-4.5,10-10S15.5,0,10,0z M7.2,7.8l2,2V5c0-0.4,0.3-0.8,0.8-0.8
                                                        s0.8,0.3,0.8,0.8v4.9l2-2l1.1,1.1l-3.3,3.3c0,0,0,0,0,0c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0,0,0s0,0,0,0
                                                        c0,0,0,0,0,0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.2L6.2,8.9L7.2,7.8z M15.8,12.9c0,0.5-0.2,1-0.5,1.3
                                                        c-0.4,0.4-0.8,0.5-1.3,0.5H6.1c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3v-3h1.5v3c0,0.1,0,0.2,0.1,0.3
                                                        c0.1,0.1,0.2,0.1,0.3,0.1h7.8c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3v-3h1.5V12.9z"/>
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- ./Redeemed -->

                <!-- Already owned -->
                <template v-if="avatarTokens.length > 0">
                    <Label tag="h2" class="-no-margin -border-top"> already owned fvck_avatar(s)</Label>

                    <div class="c-gallery -inventory || o-padding -medium-top">
                        <div class="c-gallery_item" v-for="(item, index) in avatarTokens" :key="index" data-lazy-load-wrapper>
                            <div class="c-gallery_item_inner -square">
                                <img class="c-gallery_item_image" :data-src="(item.image) ? item.image : 'assets/images/avatar-placeholder.jpg'" alt="FVCK_AVATAR" data-lazy-load>
                            </div>
                            <Label tag="p" class="c-gallery_legend -inventory">
                                {{item.name ? item.name : 'Revealing...'}}
                            </Label>
                            <div class="c-gallery_action">
                                <ul class="c-gallery_action_list">
                                    <li class="c-gallery_action_item" v-if="item.contractAddress && item.tokenId">
                                        <a :href="`https://opensea.io/assets/${item.contractAddress}/${item.tokenId}`" target="_blank" rel="noopener noreferrer" title="View on Opensea" class="c-gallery_action_link">
                                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10,0C-3-0.2-3,20.2,10,20C23,20.2,23-0.2,10,0z M4.9,10.3c0.2,0,2.6-4.4,2.8-4.1c0.7,1.6,1.1,3,0,4.2
                                                C7.6,10.4,4.8,10.7,4.9,10.3z M16.5,11.7c-1.5,0.5-2.1,3.3-3.8,3.4c-2.7-0.1-8.7,1-8.6-3.4c-0.2-0.3,3-0.1,3-0.2
                                                c0.1,1.4,1.4,1.3,2.6,1.2v-1.1c-0.1-0.1-1.7,0.2-1.5-0.1c0.7-0.9,1.3-2.1,1.1-3.4C9.2,7,8.6,6,8.2,4.9c0.1-0.2,1.3,0.3,1.5,0.3
                                                c-0.2-2,1.5-1.3,1.1,0.3c0,0,0.1,0,0.1,0c1.5,1.1,3.4,3.1,1.8,4.9c-0.5,0.7-1,1.3-2,1.2v1.1c1.1,0.1,1.7,0,2.5-0.9c0,0,3-0.9,3-0.9
                                                C16.7,10.9,16.5,11.7,16.5,11.7L16.5,11.7z"/>
                                            </svg>
                                        </a>
                                    </li>
                                    <li class="c-gallery_action_item" v-if="item.contractAddress && item.tokenId">
                                        <a :href="`https://looksrare.org/collections/${item.contractAddress}/${item.tokenId}`" target="_blank" rel="noopener noreferrer" title="View on Looksrare" class="c-gallery_action_link">
                                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.9375 7.5625L5.5 9L6.9375 10.4375C8.625 12.125 11.375 12.125 13.0625 10.4375L14.5 9L13.0625 7.5625C11.375 5.875 8.625 5.875 6.9375 7.5625ZM12.0625 9C12.0625 10.1458 11.125 11.0625 10 11.0625C8.85417 11.0625 7.9375 10.125 7.9375 9C7.9375 7.85417 8.875 6.9375 10 6.9375C11.1458 6.9375 12.0625 7.85417 12.0625 9Z"/>
                                                <path d="M10.0013 9.91667C10.5013 9.91667 10.8971 9.52083 10.8971 9.02083C10.8971 8.52083 10.5013 8.125 10.0013 8.125C9.5013 8.125 9.10547 8.52083 9.10547 9.02083C9.10547 9.5 9.5013 9.91667 10.0013 9.91667Z"/>
                                                <path d="M10 0C4.47917 0 0 4.47917 0 10C0 15.5208 4.47917 20 10 20C15.5208 20 20 15.5208 20 10C20 4.47917 15.5208 0 10 0ZM10 15.6667L3.33333 9L7.29167 5.04167H12.7083L16.6667 9L10 15.6667Z"/>
                                            </svg>
                                        </a>
                                    </li>
                                    <li class="c-gallery_action_item">
                                        <a :href="item.image" download target="_blank" rel="noopener noreferrer" title="Download" class="c-gallery_action_link" v-if="item.image">
                                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10c5.5,0,10-4.5,10-10S15.5,0,10,0z M7.2,7.8l2,2V5c0-0.4,0.3-0.8,0.8-0.8
                                                    s0.8,0.3,0.8,0.8v4.9l2-2l1.1,1.1l-3.3,3.3c0,0,0,0,0,0c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0,0,0s0,0,0,0
                                                    c0,0,0,0,0,0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.2L6.2,8.9L7.2,7.8z M15.8,12.9c0,0.5-0.2,1-0.5,1.3
                                                    c-0.4,0.4-0.8,0.5-1.3,0.5H6.1c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3v-3h1.5v3c0,0.1,0,0.2,0.1,0.3
                                                    c0.1,0.1,0.2,0.1,0.3,0.1h7.8c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3v-3h1.5V12.9z"/>
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- ./Already owned -->

                <!-- No avatar -->
                <template v-else-if="avatarTokens.length <= 0 && redeemedTokens.length <= 0">
                    <Wysiwyg :modifiers="['-outline', '-max-width']">
                        <p>You don't own any fvck_avatar(s) yet.</p>
                    </Wysiwyg>

                    <app-button
                        tag="router-link"
                        label="Redeem an avatar"
                        :url="{ name: 'Redeem' }"
                    />
                </template>
                <!-- ./No avatar -->
            </GridItem>
            <!-- ./Authenticated -->
        </Grid>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { lazyLoadImage } from '../utils/image';

import Grid from '../components/Grid/Grid.vue'
import GridItem from '../components/Grid/GridItem.vue'
import Wysiwyg from '../components/Wysiwyg.vue'
import Label from '../components/Label.vue';
import { nextTick } from '@vue/runtime-core';
import AppButton from '../components/AppButton.vue';

export default {
    name: "Redeem",

    components: {
        "Grid": Grid,
        "GridItem": GridItem,
        "Wysiwyg": Wysiwyg,
        "Label": Label,
        "app-button": AppButton
    },

    created() {
        this.setDataLoaded(true)
    },

    data() {
        return {
            avatarContractAddress: process.env.VUE_APP_MANIFOLD_AVATAR_CONTRACT_ADDRESS
        }
    },

    watch: {
        isAuthenticated(newIsAuthenticated, oldIsAuthenticated) {
            if (newIsAuthenticated && !oldIsAuthenticated) {
                this.initLazyLoad()
            }
        },

        avatarTokens() {
            this.initLazyLoad()
        }
    },

    computed: {
        ...mapState('user', [
            'isAuthenticated',
            'avatarTokens'
        ]),
        ...mapState('redeem', [
            'isRedeeming',
            'didRedeem',
            'isTxConfirmed',
            'txEtherscanUrl',
            'redeemedTokens',
        ]),
    },

    mounted() {
        this.initLazyLoad()
    },

    methods: {
        ...mapActions('main', [
            'setDataLoaded',
        ]),

        async initLazyLoad() {
            //wait for DOM to update
            await nextTick()

            const lazyLoadItems = Array.from(this.$el.querySelectorAll('[data-lazy-load]'));

            // Set observer options
            const observerOptions = {
                rootMargin: "-1px -1px -1px -1px",
            };

            // Set observer callback
            const onIntersect = (entries) => {
                entries.forEach((entry) => {
                    // Then apply view methods
                    if (entry.intersectionRatio > 0) {
                        // Inview
                        lazyLoadImage(entry.target)

                        this.observer.unobserve(entry.target)
                    } else if (!this.isFirstObserve) {
                        // Out of view
                    }
                });

                this.isFirstObserve = false;
            };

            //Create a single IntersectionObserver(IO) that watches all elements
            this.observer = new IntersectionObserver(onIntersect, observerOptions);

            // Observe each elements
            let index = 0;
            while (index < lazyLoadItems.length) {
                const $view = lazyLoadItems[index];

                this.observer.observe($view);
                index++;
            }
        }
    },

    beforeUnmount() {
        this.observer?.disconnect();
    }
};
</script>
