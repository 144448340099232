<template>
    <component
        :is="tag"
        :class="['c-label']"
    >
        <slot></slot>
    </component>
</template>

<script>
    export default {
        name: "Label",
        props: {
            tag: {
                type: String,
                default: 'span'
            }
        }
    }
</script>