<template>
    <vue-nav />
    <vue-logo v-if="$route.name != 'Home'" />
    <vue-socials />

    <router-view :key="$route.fullPath" />

    <vue-loader />
</template>

<script>
    import { mapActions } from 'vuex';

    //Global styles
    import "./assets/styles/main.scss";

    //Components
    import LoaderVue from './components/Loader.vue';
    import Logo from './components/Logo.vue';
    import NavVue from './components/Nav.vue';
    import SocialsVue from './components/Socials.vue';

    //Mixins
    import initAppMixin from "./mixins/initAppMixin";

    export default {
        name: "App",
        mixins: [
            initAppMixin
        ],
        components: {
            "vue-loader": LoaderVue,
            "vue-logo": Logo,
            "vue-nav": NavVue,
            "vue-socials": SocialsVue
        },

        created() {
            /**
             * Authentification event
             * https://docs.manifoldxyz.dev/docs/connect-widget/connect-overview
             */
            window.addEventListener('m-authenticated', async (event) => {
                //Extract data from authentification
                const userAddress = event?.detail?.address
                const client = event?.detail?.client

                //hotfix for rinkeby
                client.network = parseInt(process.env.VUE_APP_MANIFOLD_NETWORK)

                //Populate user state
                this.setUserAuthenticated(true)
                this.setUserAddress(userAddress)

                /**
                 * Check if the user owns avatars in his wallet.
                 * Then update user state.
                 * https://docs.manifoldxyz.dev/docs/manifold-data-apis/apis-nfts/getNFTsOfOwner
                 */
                if(client?.token === '') return;

                //Filters
                const filters = [
                    {
                        contractAddress: process.env.VUE_APP_MANIFOLD_AVATAR_CONTRACT_ADDRESS
                    }
                ];

                //Get avatars of owner
                client.getNFTsOfOwner({
                    filters: filters
                }).then((tokens) => {
                    if(tokens.length > 0) {
                        this.setUserAvatarTokens(tokens);
                    }
                })
            })

            /**
             * Disconnect event
             * https://docs.manifoldxyz.dev/docs/connect-widget/connect-overview
             */
            window.addEventListener('m-unauthenticated', async () => {
                this.setUserAuthenticated(false)
                this.setUserAddress(null)
            })
        },

        methods: {
            ...mapActions('user', [
                'setUserAuthenticated',
                'setUserAddress',
                'setUserAvatarTokens'
            ])
        }
    };
</script>
