<template>
    <div class="c-spinner-wrapper" :class="{'-absolute': isAbsolute, '-square': isSquare}">
        <div class="c-spinner">
            <svg viewBox="25 25 50 50">
                <circle cx="50" cy="50" r="20"></circle>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Spinner",
        props: {
            isAbsolute: {
                type: Boolean,
                default: false
            },
            isSquare: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
