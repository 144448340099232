<template>
    <div class="u-background || o-padding -big-top || u-overflow-hidden">
        <div class="o-container -layout">
            <Grid :modifiers="['-col-4', '-col-12@from-medium', '-gutter']">
                <GridItem
                    :col-start-medium="4"
                    :col-end-medium="10"
                >
                    <Label tag="h2">
                        How to get an avatar
                    </Label>

                    <Wysiwyg :modifiers="['-indent', '-stacked', '-big', 'o-padding', '-small-bottom']">
                        <p>Fvck_Avatar will be launched in 4 phases using a redeem token called ESSENCE//.</p>
                    </Wysiwyg>
                </GridItem>
            </Grid>

            <div class="c-grid -no-grid">
                <div class="c-phases-carousel || swiper" ref="carousel">
                    <div class="c-phases-carousel_top">
                        <Wysiwyg :modifiers="['-stacked', 'o-padding']">
                            Here's how it'll work :
                        </Wysiwyg>
                        <div class="c-phases-carousel_navigation">
                            <button type="button" class="c-phases-carousel_navigation_button -prev" ref="prev">
                                <span class="u-screen-reader-text">Previous slide</span>
                                <svg version="1.1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                     x="0px" y="0px" viewBox="0 0 48 47" style="enable-background:new 0 0 48 47;" xml:space="preserve">
                                    <path vector-effect="non-scaling-stroke" d="M24.5,0.3L1,23.8 M1,23.7h46 M25,46.7l-24-23"/>
                                </svg>
                            </button>
                            <button type="button" class="c-phases-carousel_navigation_button -next" ref="next">
                                <span class="u-screen-reader-text">Next slide</span>
                                <svg version="1.1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                     x="0px" y="0px" viewBox="0 0 48 47" style="enable-background:new 0 0 48 47;" xml:space="preserve">
                                    <path vector-effect="non-scaling-stroke" d="M23.5,46.7L47,23.2 M47,23.3H1 M23,0.3l24,23"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="c-phases-carousel_wrapper || swiper-wrapper">
                        <div class="c-phases-carousel_slide || swiper-slide">
                            <Wysiwyg>
                                <h3>Phase 1 <span class="u-blink">_</span> Free Claim : March 23rd <br>Completed</h3>
                                <ul>
                                    <li>Every FVCK_CRYSTAL// holder can redeem one free ESSENCE//.
                                        <br><em>→ 1 ESSENCE// per crystal</em>
                                    </li>
                                    <li>Every limited 1/1 artwork collector can redeem one free ESSENCE//.
                                        <br><em> → 1 ESSENCE// per NFT</em>
                                    </li>
                                </ul>
                            </Wysiwyg>
                        </div>

                        <div class="c-phases-carousel_slide || swiper-slide">
                            <Wysiwyg>
                                <h3>Phase 2 <span class="u-blink">_</span> Discount Pre-Sale : March 25th <br>Completed</h3>
                                <ul>
                                    <li>Every FVCK_CRYSTAL// holder can mint an ESSENCE// for 0.2 ETH.
                                        <br><em>→ 1 ESSENCE// per crystal</em>
                                    </li>
                                    <li>Every holder with an eligible <a href="https://docs.google.com/spreadsheets/d/11OVLW6YLI84O20uXqtyENfkGW-RADBpZQH40gM2eLMY/" target="_blank" rel="noopener">FVCKRENDER NFTs</a> can mint an ESSENCE// for 0.2 ETH.
                                        <br><em>→ 1 ESSENCE// per NFT</em>
                                    </li>
                                    <li>Every limited 1/1 artwork collector can mint their ESSENCE// for 0.2 ETH.
                                        <br><em>→ 5 ESSENCE// per NFT</em>
                                    </li>
                                    <li>Winners from giveaways or contests can mint an ESSENCE// for 0.2 ETH.
                                        <br><em>→ 1 ESSENCE// per wallet</em>
                                    </li>
                                </ul>
                            </Wysiwyg>
                        </div>

                        <div class="c-phases-carousel_slide || swiper-slide">
                            <Wysiwyg>
                                <h3>Phase 3 <span class="u-blink">_</span> Public Sale: March 27th <br>Completed</h3>
                                <ul>
                                    <li>If there are still some ESSENCE// left after phases 1 and 2, sale will go public for 0.469 ETH.
                                        <br><em>→ 1 ESSENCE// per transaction</em>
                                    </li>
                                </ul>
                            </Wysiwyg>
                        </div>

                        <div class="c-phases-carousel_slide || swiper-slide">
                            <Wysiwyg>
                                <h3>Phase 4 <span class="u-blink">_</span> Redemption : April 6th</h3>
                                <ul>
                                    <li>Redemption starts, ESSENCE// can be burned to redeem a FVCK_AVATAR//. The avatars reveal automatically when burning your ESSENCE//
                                        <br><em>→ 1 ESSENCE// for 1 FVCK_AVATAR</em>
                                    </li>
                                </ul>
                            </Wysiwyg>
                        </div>

                        <div class="c-phases-carousel_slide || swiper-slide">
                            <video class="c-phases-carousel_video" src="assets/videos/essence.mp4" muted ref="video" loop playsinline="true"></video>
                        </div>
                    </div>
                </div>
            </div>

            <Grid :modifiers="['-col-4', '-col-12@from-medium', '-gutter']">
                <GridItem
                    :col-start-medium="4"
                    :col-end-medium="10"
                >
                    <Wysiwyg class="o-padding -small-top">
                        <p class="c-wysiwyg_warning">The snapshot has now been taken. Any purchases after this point will not count towards our allowlist! <br>Time of the snapshot: Sunday, March 20, 2022 3:00 PM EST</p>
                        <p>For more information, read our <a href="#faq">FAQ</a></p>
                    </Wysiwyg>
                </GridItem>
            </Grid>
        </div>
    </div>
</template>

<script>
    import { Swiper, Navigation } from 'swiper';

    import Grid from './Grid/Grid.vue';
    import GridItem from './Grid/GridItem.vue';
    import Wysiwyg from './Wysiwyg';
    import Label from './Label';

    export default {
        name: "Phases",
        props: {},
        components: {
            "Grid": Grid,
            "GridItem": GridItem,
            "Wysiwyg": Wysiwyg,
            "Label": Label
        },

        data() {
            return {}
        },

        mounted() {
            this.initSwiper()

            const $video = this.$refs.video

            // Set observer options
            const observerOptions = {
                rootMargin: "-1px -1px -1px -1px",
            };

            // Set observer callback
            const onIntersect = (entries) => {
                entries.forEach((entry) => {
                    // Then apply view methods
                    if (entry.intersectionRatio > 0) {
                        //inview
                        if($video.paused) {
                            $video.play()
                            this.isInview = true
                        }
                    } else if (!this.isFirstObserve) {
                        // Out of view
                        if(!$video.paused) {
                            $video.pause()
                            this.isInview = false
                        }
                    }
                });

                this.isFirstObserve = false;
            };

            //Create a single IntersectionObserver(IO) that watch hero element
            this.observer = new IntersectionObserver(onIntersect, observerOptions);
            this.observer.observe(this.$refs.video);
        },

        beforeUnmount() {
            this.swiperInstance?.destroy()
            this.observer?.disconnect();
        },

        methods: {
            initSwiper() {
                Swiper.use([Navigation]);

                this.swiperInstance = new Swiper(this.$refs.carousel, {
                    slidesPerView: 1.1,
                    spaceBetween: 10,
                    grabCursor: true,
                    navigation: {
                        prevEl: this.$refs.prev,
                        nextEl: this.$refs.next
                    },
                    breakpoints: {
                        1000: {
                            slidesPerView: 2.33,
                            spaceBetween: 20
                        }
                    }
                });
            }
        }
    }
</script>
