const mutations = {
    SET_FONTS_LOADED: (state, val) => {
        state.isFontsLoaded = val;
    },
    SET_DATA_LOADED: (state, val) => {
        state.isDataLoaded = val;
    },
    SET_IS_SOLD_OUT: (state, val) => {
        state.isSoldOut = val;
    },
    SET_SUPPLY_SOLD: (state, val) => {
        state.supplySold = val;
    },
    SET_CURRENT_SALE_PHASE: (state, val) => {
        state.currentSalePhase = val;
    },
    SET_HAS_SALE_ENDED: (state, val) => {
        state.hasSaleEnded = val;
    },
    SET_TOKENS_REMAINING: (state, val) => {
        state.tokensRemaining = val;
    },
    SET_TOTAL_SUPPLY: (state, val) => {
        state.totalSupply = val;
    },
    SET_PRESALE_PRICE_ETH: (state, val) => {
        state.presalePriceEth = val;
    },
    SET_PUBLIC_SALE_PRICE_ETH: (state, val) => {
        state.publicSalePriceEth = val;
    },
    SET_USER_CLAIM_AMOUNT: (state, val) => {
        state.userClaimAmount = val;
    },
    SET_HAS_USER_ALREADY_CLAIMED: (state, val) => {
        state.hasUserAlreadyClaimed = val;
    },
    SET_PRESALE_PURCHASE_LIMIT: (state, val) => {
        state.presalePurchaseLimit = val;
    },
    SET_HAS_USER_ALREADY_USED_PRESALE_PURCHASE: (state, val) => {
        state.hasUserAlreadyUsedPresalePurchase = val;
    },
    SET_NUM_TOKENS_USER_HAS_MINTED: (state, val) => {
        state.numTokensUserHasMinted = val;
    },
    SET_IS_COLLECTIBLE_WIDGET_LOADED: (state, val) => {
        state.isCollectibleWidgetLoaded = val;
    },
    SET_TRANSACTION_LIMIT: (state, val) => {
        state.transactionLimit = val;
    }
}

export default mutations;
