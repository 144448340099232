// GridItem
// Main grid styles are set in _grid.scss
// Layout depending Media Queries are set with the css variables below.
// 

<template>
    <div 
        class="c-grid_item"
        :class="classes"
        :style="styles"
    >
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "GridItem",

        data() {
            return {
                mqs: null
            };
        },

        created() {
            let mqStyles = [
                {
                    mq: null,
                    start: this.colStart,
                    end: this.colEnd
                },
                {
                    mq: 'big',
                    start: this.colStartBig,
                    end: this.colEndBig
                },
                {
                    mq: 'large',
                    start: this.colStartLarge,
                    end: this.colEndLarge
                },
                {
                    mq: 'medium',
                    start: this.colStartMedium,
                    end: this.colEndMedium
                },
                {
                    mq: 'small',
                    start: this.colStartSmall,
                    end: this.colEndSmall
                },
                {
                    mq: 'tiny',
                    start: this.colStartTiny,
                    end: this.colEndTiny
                }
            ]

            //Filter only valid columns
            this.mqs = mqStyles.filter((item) => {
                return item?.start && item?.end
            }) 
        },

        computed: {
            styles() { 
                let styles = [];               

                //build final styles variables array
                this.mqs.forEach((item) => {
                    if(item.mq != null && item.start && item.end) {
                        styles.push(`--col-start-${item.mq}: ${item.start}`)
                        styles.push(`--col-end-${item.mq}: ${item.end}`)
                    } else {
                        styles.push(`--col-start: ${item.start}`)
                        styles.push(`--col-end: ${item.end}`)
                    }
                })                

                return styles
            },

            classes() {                
                return this.mqs.map(item => `u-grid-${item.mq}`)
            }
        },

        props: {
            colStart: {
                type: [String, Number]
            },

            colEnd: {
                type: [String, Number]
            },

            colStartBig: {
                type: [String, Number]
            },

            colEndBig: {
                type: [String, Number]
            },

            colStartLarge: {
                type: [String, Number]
            },

            colEndLarge: {
                type: [String, Number]
            },

            colStartMedium: {
                type: [String, Number]
            },

            colEndMedium: {
                type: [String, Number]
            },

            colStartSmall: {
                type: [String, Number]
            },

            colEndSmall: {
                type: [String, Number]
            },

            colStartTiny: {
                type: [String, Number]
            },

            colEndTiny: {
                type: [String, Number]
            }
        }
    }
</script>