<template>
    <div 
        class="c-grid"
        :class="modifiers"
    >
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Grid",
        props: []
    }
</script>