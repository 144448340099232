<template>
    <footer class="c-footer">
        <div class="o-container || c-footer_container">
            <Grid :modifiers="['-col-4', '-col-12@from-medium', '-gutter']">
                <GridItem 
                    :col-start-medium="8"
                    :col-end-medium="13"
                >
                    <div class="c-footer_inner">
                        <h2 class="c-heading -h2 || c-footer_title">
                            To keep up with all the latest announcements, giveaways and launches, please visit our <a href="https://discord.gg/lvcidia" target="_blank" rel="noopener" class="c-footer_link">Discord</a> & <a href="https://twitter.com/lvcidia" target="_blank" rel="noopener noreferrer" class="c-footer_link">Twitter</a> channels.
                        </h2>

                        <div class="c-footer_team">
                            <Label tag="h2">
                                team
                            </Label>
                            <ul class="c-footer_team_list">
                                <li class="c-footer_team_item">
                                    <a href="https://twitter.com/fvckrender" target="_blank" rel="noopener noreferrer" class="c-footer_team_link">
                                        fvckrender
                                    </a>
                                    <Label tag="h3">
                                        artist
                                    </Label>
                                </li>
                                <li class="c-footer_team_item">
                                    <a href="https://twitter.com/LandisTanaka" target="_blank" rel="noopener noreferrer" class="c-footer_team_link">
                                        landis tanaka
                                    </a>
                                    <Label tag="h3">
                                        art dev
                                    </Label>
                                </li>
                                <li class="c-footer_team_item">
                                    <a href="https://twitter.com/manifoldxyz" target="_blank" rel="noopener noreferrer" class="c-footer_team_link">
                                        manifold
                                    </a>
                                    <Label tag="h3">
                                        smart contract
                                    </Label>
                                </li>
                                <li class="c-footer_team_item">
                                    <a href="https://twitter.com/devenini" target="_blank" rel="noopener noreferrer" class="c-footer_team_link">
                                        devenini
                                    </a> &
                                    <a href="https://twitter.com/plcossette" target="_blank" rel="noopener noreferrer" class="c-footer_team_link">
                                        pl cossette
                                    </a>
                                    <Label tag="h3">
                                        website
                                    </Label>
                                </li>
                                <li class="c-footer_team_item">
                                    <a href="https://twitter.com/Capslockmanny" target="_blank" rel="noopener noreferrer" class="c-footer_team_link">
                                        capslockmanny
                                    </a>
                                    <Label tag="h3">
                                        copywriter
                                    </Label>
                                </li>
                            </ul>
                        </div>
                        <div class="c-footer_lvcidia">
                            <Label tag="p">
                                visit
                            </Label>
                            <a href="https://lvcidia.xyz/" target="_blank" rel="noopener noreferrer" class="c-footer_lvcidia_link">
                                <span class="u-screen-reader-text">Visit Lvcidia</span>
                                <svg class="c-footer_lvcidia_link_logo" aria-hidden="true" focusable="false" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                     viewBox="0 0 3166.3 702.7" style="enable-background:new 0 0 3166.3 702.7;" xml:space="preserve">
                                    <path d="M2.7,2h163v559h201l-140,141H2.7V2z M196.7,2h170l262,700h-170L196.7,2z M768.7,2v404l-163,164V2H768.7z"/>
                                    <path d="M943.7,143c-13,41-21,85-21,129c0,195,104,288,306,288h223l-141,142h-146c-221,0-361-115-361-306
                                        C803.7,291,858.7,188,943.7,143 M1451.7,2v211l-163,163V143h-345c15-79,86-141,166-141H1451.7z"/>
                                    <rect x="1501.7" y="2" width="163" height="700"/>
                                    <path d="M2056.7,2c80,0,151,62,166,141h-345v233l-163-163V2H2056.7z M2362.7,396c0,191-140,306-361,306h-146l-141-142h223
                                        c202,0,306-93,306-288c0-44-8-88-21-129C2307.7,188,2362.7,291,2362.7,396"/>
                                    <path d="M2560.7,570l-163-164V2h163V570z M2537.7,702l262-700h170l-262,700H2537.7z M2939.7,702l-140-141h201V2h163v700H2939.7z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </GridItem>
            </Grid>
        </div>
        <div class="c-footer_background" style="background-image: url(assets/images/background-footer.jpg);"></div>
    </footer>
</template>

<script>
    import Grid from './Grid/Grid.vue'
    import GridItem from './Grid/GridItem.vue'
    import Label from './Label.vue' 

    export default {
        name: "Footer",
        components: {
            "Grid": Grid,
            "GridItem": GridItem,
            "Label": Label,
        }
    }
</script>