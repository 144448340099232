const actions = {
    setIsRedeeming: (context, val) => {
        context.commit("SET_IS_REDEEMING", val);
    },
    setDidRedeem: (context, val) => {
        context.commit("SET_DID_REDEEM", val);
    },
    setIsTxConfirmed: (context, val) => {
        context.commit("SET_IS_TX_CONFIRMED", val);
    },
    setTxEtherscanUrl: (context, val) => {
        context.commit("SET_TX_ETHERSCAN_URL", val);
    },
    setRedeemedTokens: (context, val) => {
        context.commit("SET_REDEEMED_TOKENS", val);
    },
    setRedemptionErrorMessage: (context, val) => {
        context.commit("SET_REDEMPTION_ERROR_MESSAGE", val);
    },
    setIsRedemptionEnabled: (context, val) => {
        context.commit("SET_IS_REDEMPTION_ENABLED", val);
    },
};

export default actions;
