<template>
    <div v-if="!isLoaded" class="c-loader">
        <p>loading...</p>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

const html = document.documentElement;

export default {
    name: "Loader",

    computed: {
        ...mapState("main", ["isFontsLoaded"]),
        ...mapState("metrics", ["width"]),
        ...mapGetters("main", ['isAllLoaded'])
    },

    data() {
        return {
            isLoaded: false,
        };
    },

    watch: {
        isFontsLoaded: "onFontsLoaded",
        width: "onResize",
        isAllLoaded: "onAllLoaded"
    },

    created() {
        this.hasLoadOnce = false;
    },

    mounted() {
        if (this.isFontsLoaded) {
            this.onFontsLoaded();
        }
    },

    methods: {
        onFontsLoaded() {

        },

        onResize() {

        },

        onAllLoaded(isLoaded) {
            setTimeout(() => {
                this.isLoaded = isLoaded;

                // check status
                if (!this.isLoaded) {
                    html.classList.remove("is-loaded");
                    html.classList.add("is-loading");
                }

                // If it's loaded
                if (this.isLoaded) {
                    if (!this.hasLoadOnce) {
                        html.classList.add("is-first-hit");
                    }
                    this.hasLoadOnce = true

                    html.classList.remove("is-loading");
                    html.classList.add("is-loaded");
                }
            }, 200)
        },
    },
};
</script>
