<template>
    <nav class="c-socials" :class="{'-hide-mobile': currentRouteName == 'Mint', '-hide-mobile': currentRouteName == 'Redeem'}" aria-label="Social nav">
        <ul class="c-socials_list">
            <li class="c-socials_list_item">
                <app-button
                    tag="a"
                    label="View on looksrare"
                    url="https://looksrare.org/collections/0x10cDcb5a80e888ec9e9154439e86b911f684Da7B"
                    :isTargetBlank="true"
                />
            </li>

            <li class="c-socials_list_item">
                <app-button
                    tag="a"
                    label="View on opensea"
                    url="https://opensea.io/collection/lvcidiaavatars"
                    :isTargetBlank="true"
                />
            </li>

            <li class="c-socials_list_item">
                <app-button
                    tag="a"
                    label="Follow on twitter"
                    url="https://twitter.com/lvcidia"
                    :isTargetBlank="true"
                />
            </li>

            <li class="c-socials_list_item">
                <app-button
                    tag="a"
                    label="Join the discord"
                    url="https://discord.gg/lvcidia"
                    :isTargetBlank="true"
                />
            </li>
        </ul>
    </nav>
</template>

<script>
    import { useRoute } from 'vue-router';
    import AppButton from './AppButton.vue';

    export default {
        name: "Nav",

        components: {
            "app-button": AppButton
        },

        computed: {
            currentRouteName() {
                return useRoute().name;
            }
        },
    };
</script>
