<template>
    <nav class="c-nav" aria-label="Main nav">
        <ul class="c-nav_list">
            <li class="c-nav_list_item" v-if="manifoldClientID">
                <div
                    data-widget="m-oauth-connect"
                    :data-client-id="manifoldClientID"
                    :data-app-name="manifoldAppName"
                    :data-redirect-uri="manifoldRedirectURI"
                    :data-network="manifoldNetwork"
                    data-show-chain
                >
                </div>
            </li>

            <li class="c-nav_list_item" v-if="currentRouteName != 'Redeem'">
                <app-button
                    tag="router-link"
                    label="Redeem an avatar"
                    :url="{ name: 'Redeem' }"
                />
            </li>

            <li class="c-nav_list_item" v-if="currentRouteName != 'Inventory' && isAuthenticated">
                <app-button
                    tag="router-link"
                    label="My avatars"
                    :url="{ name: 'Inventory' }"
                />
            </li>
        </ul>
    </nav>
</template>

<script>
    import AppButton from './AppButton.vue';
    import { useRoute } from 'vue-router';
    import { mapState } from 'vuex';

    export default {
        name: "Nav",

        components: {
            "app-button": AppButton
        },

        mounted() {
            // console.log(useRoute())
        },

        computed: {
            ...mapState('user', [
                'isAuthenticated'
            ]),

            currentRouteName() {
                return useRoute().name;
            }
        },

        data() {
            return {
                manifoldClientID: process.env.VUE_APP_MANIFOLD_CLIENT_ID,
                manifoldAppName: process.env.VUE_APP_MANIFOLD_APP_NAME,
                manifoldRedirectURI: process.env.VUE_APP_MANIFOLD_REDIRECT_URI,
                manifoldNetwork: process.env.VUE_APP_MANIFOLD_NETWORK
            }
        }
    };
</script>
