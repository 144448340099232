const actions = {
    setUserAuthenticated: (context, val) => {
        context.commit("SET_USER_AUTHENTICATED", val);
    },
    setUserAddress: (context, val) => {
        context.commit("SET_USER_ADDRESS", val);
    },
    setUserBalanceOfEssenceTokens: (context, val) => {
        context.commit("SET_USER_BALANCE_OF_ESSENCE_TOKENS", val);
    },
    setUserAvatarTokens: (context, val) => {
        context.commit("SET_USER_AVATAR_TOKENS", val);
    },
};

export default actions;
