import { createRouter, createWebHistory } from "vue-router";

import Home from "../views/Home.vue";
import Redeem from "../views/Redeem.vue";
import Inventory from "../views/Inventory.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/redeem",
        name: "Redeem",
        component: Redeem,
    },
    {
         path: "/inventory",
         name: "Inventory",
         component: Inventory,
    },
    {
        path: "/mint",
        name: "Mint",
        redirect: () => {
            // the function receives the target route as the argument
            // we return a redirect path/location here.
            return { path: '/' }
        }
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: () => {
            // the function receives the target route as the argument
            // we return a redirect path/location here.
            return { path: '/' }
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

/**
 * Dispatch an event so manifold widgets' are re-bound or destroyed after route changes.
 */
router.afterEach(() => {
    window.dispatchEvent(new CustomEvent("m-refresh-widgets"));
});

export default router;
