<template>
    <router-link class="c-logo" :class="{ [`${classes}`]: classes, 'is-hidden': !isVisible }" :to="{ name: 'Home' }">
        <span class="u-screen-reader-text">fvck_avatar //</span>
        <svg version="1.1" class="c-logo_text" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             x="0px" y="0px" viewBox="0 0 758 265" style="enable-background:new 0 0 758 265;" xml:space="preserve">
            <path class="c-logo_line" d="M28.7,126V72h52.7V58H28.7V17.2h67.1v-14H11.9V126H28.7z M166.7,126L216.2,3.1H199l-40.4,103.4h-0.8L116.3,3.1H99L148.3,126
                H166.7z M215,64.6c0,37.8,26.3,63.8,60.3,63.8c35.1,0,55-21.6,57.3-53.2l-16.4-0.8c-1.4,22.6-15.6,39.6-41,39.6
                c-25,0-42.9-19.1-42.9-49.3s17.9-49.3,42.9-49.3c25.4,0,39.6,17,41,39.6l16.4-0.8C330.3,22.6,310.4,1,275.3,1
                C241.3,0.8,215,26.7,215,64.6z M449,126l-58.7-68.8l52.8-54H423l-59.5,60.6V3.1h-17V126h17V82.1l14.2-14.4l50.7,58.3H449z"/>
            <path class="c-logo_line" d="M53.1,137.7L3,260.5h16.6l14.4-36h56l14.8,36.1h17.9L71.6,137.7H53.1z M61.3,156.4h0.8L84.5,211H39.3L61.3,156.4z
                 M181,260.5l49.5-122.9h-17.2L173,241h-0.8l-41.5-103.4h-17.4l49.3,122.9H181z M271.3,137.7l-50.1,122.9h16.6l14.4-36.1h56
                l14.8,36.1h18l-51.1-122.9L271.3,137.7L271.3,137.7z M279.5,156.4h0.8l22.4,54.6h-45.2L279.5,156.4z M389.8,260.5V151.7h45.4v-14
                H328.4v14H373v108.8H389.8z M472.8,137.7l-50.1,122.9h16.6l14.4-36.1h56l14.8,36.1h17.9l-51.1-122.9L472.8,137.7L472.8,137.7z
                 M481,156.4h0.8l22.4,54.6H459L481,156.4z M650.6,260.5c-4.1-4.5-4.7-11.5-6.2-29.4c-1.2-14.4-8.4-23.6-23.4-26.5
                c15-1.8,27.9-11.7,27.9-30.6c0-21.8-15-36.3-46.2-36.3h-49.9v122.9h16.6V211H604c16.8,0,23.2,9.2,24,22.8c1,14.4,2.7,23.4,5.1,26.7
                H650.6z M569.3,197.5v-46h32.4c20.9,0,30.2,9,30.2,23c0,15.2-9.4,23-30.2,23H569.3z"/>
            <g class="c-logo_blink">
                <rect x="471" y="117" width="103" height="9"/>
            </g>
        </svg>

        <svg class="c-logo_diagonal" aria-hidden="true" focusable="false" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 91.3 125.9" style="enable-background:new 0 0 91.3 125.9;" xml:space="preserve">
            <path d="M43.7,0.3h13.2L13.7,125.7H0.4L43.7,0.3z"/>
            <path d="M78.3,0.3h13.2L48.4,125.7H35L78.3,0.3z"/>
        </svg>
    </router-link>
</template>

<script>
    export default {
        name: "Logo",
        props: {
            classes: {
                type: String
            },
            isVisible: {
                type: Boolean,
                default: true
            }
        }
    }
</script>