import actions from './actions';
import mutations from './mutations';

const state = {
    width: 0,
    height: 0,
    devicePixelRatio: 1
};

const store = {
    namespaced: true,
    state,
    actions,
    mutations,
};

export default store;
