import { createStore } from "vuex" 
import main from './main/index'
import metrics from './metrics/index'
import user from './user/index'
import redeem from "./redeem/index";

const store = createStore({
    namespaced: true,
    modules: {
        main,
        metrics,
        user,
        redeem,
    },
});

export default store;