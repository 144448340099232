import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import modifiersMixin from "./mixins/modifiersMixin";

const app = createApp(App);

// Utilities
app.use(store);
app.use(router);

// Mixins
app.mixin(modifiersMixin);

// Mount app
app.mount("#app");
