const mutations = {
    SET_IS_REDEEMING: (state, val) => {
        state.isRedeeming = val;
    },
    SET_DID_REDEEM: (state, val) => {
        state.didReeem = val;
    },
    SET_IS_TX_CONFIRMED: (state, val) => {
        state.isTxConfirmed = val;
    },
    SET_TX_ETHERSCAN_URL: (state, val) => {
        state.txEtherscanUrl = val;
    },
    SET_REDEMPTION_ERROR_MESSAGE: (state, val) => {
        state.redemptionErrorMessage = val;
    },
    SET_REDEEMED_TOKENS: (state, val) => {
        state.redeemedTokens = val;
    },
    SET_IS_REDEMPTION_ENABLED: (state, val) => {
        state.isRedemptionEnabled = val;
    },
};

export default mutations;
