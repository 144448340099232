<template>
    <component
        :is="tag"
        :to="isRouterLink ? url : undefined"
        :href="isLink ? url : undefined"
        :title="title"
        class="c-button"
        :class="modifiers"
        :target="(isLink && isTargetBlank) ? '_blank' : undefined"
        :rel="(isLink && isTargetBlank) ? 'noopener' : undefined"
    >
        <span class="c-button_label">
            <slot>{{ label }}</slot>
        </span>
    </component>
</template>

<script>
    export default {
        name: 'AppButton',

        props: {
            tag: {
                type: String,
                default: 'button'
            },
            url: {
                type: [String, Object],
                default: null
            },
            label: {
                type: String,
                default: null
            },
            isTargetBlank: {
                type: Boolean,
                required: false
            }
        },

        computed: {
            isRouterLink() {
                return this.tag === 'router-link'
            },
            isLink() {
                return this.tag === 'a'
            },
            title() {
                return this.isRouterLink || this.isLink ? this.label : undefined
            }
        }
    }
</script>