import actions from './actions';
import mutations from './mutations';

const state = {
    isAuthenticated: false,
    connectedAddress: null,
    balanceOfEssenceTokens: 0,
    avatarTokens: []
};

const store = {
    namespaced: true,
    state,
    actions,
    mutations
}

export default store;
