<template>
    <div class="u-background o-padding -big-top">
        <div class="o-container">
            <Grid :modifiers="['-col-4', '-col-12@from-medium', '-gutter']">
                <GridItem
                    :col-start-medium="4"
                    :col-end-medium="10"
                >
                    <Label tag="h2">
                        Handmade
                    </Label>

                    <Wysiwyg :modifiers="['-indent', '-stacked', '-big']">
                        <p>Every handmade Fvck_Crystal holder will automatically get a handmade Fvck_Avatar. But it will also be possible to randomly get one when burning your Essence in phase 4.</p>
                    </Wysiwyg>
                </GridItem>
            </Grid>

            <Grid :modifiers="['-col-4', '-col-12@from-medium', '-gutter']" class="o-padding -medium-top">
                <GridItem
                    :col-start-medium="4"
                    :col-end-medium="7"
                >

                    <Wysiwyg :modifiers="['-two-column']">
                        <p>To create those 1/1, FVCKRENDER partnered with emerging digital artists, giving them total freedom. Those artists are: </p>
                        <ul>
                            <li>Jason Ebeyer</li>
                            <li>Smeccea</li>
                            <li>Guitarana</li>
                            <li>Baeige</li>
                            <li>Serwah</li>
                            <li>Aeforia</li>
                            <li>Aurorae</li>
                            <li>Victor Mosquera</li>
                            <li>ThankyouX</li>
                            <li>Mpkoz</li>
                            <li>Numosis</li>
                            <li>OSKA</li>
                            <li>Landis Tanaka</li>
                            <li>Visadimension</li>
                        </ul>
                    </Wysiwyg>
                </GridItem>
            </Grid>

            <div class="c-gallery -full || o-padding -medium-top">
                <div class="c-gallery_item" v-for="(item, index) in items" :key="index" data-lazy-load-wrapper>
                    <div class="c-gallery_item_inner -square">
                        <img class="c-gallery_item_image" :data-src="item.url" alt="FVCK_AVATAR" data-lazy-load>
                    </div>
                    <Label tag="p" class="c-gallery_legend">
                        {{item.artist}}
                    </Label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { lazyLoadImage } from "../utils/image";

    import Grid from './Grid/Grid.vue';
    import GridItem from './Grid/GridItem.vue';
    import Label from './Label';
    import Wysiwyg from './Wysiwyg';

    export default {
        name: "Handmade",
        props: {},
        components: {
            "Grid": Grid,
            "GridItem": GridItem,
            "Wysiwyg": Wysiwyg,
            "Label": Label
        },

        data() {
            return {
                items: [
                    {url: 'assets/images/avatar_06.jpg', artist: 'handmade by FVCKRENDER'},
                    {url: 'assets/images/avatar_07.jpg', artist: 'handmade by Serwah'},
                    {url: 'assets/images/avatar_09.jpg', artist: 'handmade by Numosis'}
                ]
            }
        },

        mounted() {
            const lazyLoadItems = Array.from(this.$el.querySelectorAll('[data-lazy-load]'))

            // Set observer options
            const observerOptions = {
                rootMargin: "-1px -1px -1px -1px",
            };

            // Set observer callback
            const onIntersect = (entries) => {
                entries.forEach((entry) => {
                    // Then apply view methods
                    if (entry.intersectionRatio > 0) {
                        // Inview
                        lazyLoadImage(entry.target)

                        this.observer.unobserve(entry.target)
                    } else if (!this.isFirstObserve) {
                        // Out of view
                    }
                });

                this.isFirstObserve = false;
            };

            //Create a single IntersectionObserver(IO) that watches all elements
            this.observer = new IntersectionObserver(onIntersect, observerOptions);

            // Observe each elements
            let index = 0;
            while (index < lazyLoadItems.length) {
                const $view = lazyLoadItems[index];

                this.observer.observe($view);
                index++;
            }
        },

        beforeUnmount() {
            this.observer?.disconnect();
        }
    }
</script>
