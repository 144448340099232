<template>
    <div class="u-background">
        <div class="c-faq || o-container">
            <Grid :modifiers="['-col-4', '-col-12@from-medium', '-gutter']" id="faq">
                <GridItem 
                    :col-start-medium="4"
                    :col-end-medium="10"
                >
                    <Label tag="h2">
                        Frequently asked questions
                    </Label>

                    <Accordion>
                        <AccordionItem :label="accordion.label" v-for="(accordion, index) in accordionsData" :key="index">
                            <Wysiwyg :content="accordion.content" />
                        </AccordionItem>
                    </Accordion>
                </GridItem>
            </Grid>
        </div>
    </div>
</template>

<script>
    import Grid from './Grid/Grid.vue'
    import GridItem from './Grid/GridItem.vue'
    import Wysiwyg from './Wysiwyg.vue'
    import Label from './Label.vue' 
    import Accordion from './Accordion/Accordion.vue'
    import AccordionItem from './Accordion/AccordionItem.vue'

    export default {
        name: "FAQ",
        components: {
            "Grid": Grid,
            "GridItem": GridItem,
            "Wysiwyg": Wysiwyg,
            "Label": Label,
            "Accordion": Accordion,
            "AccordionItem": AccordionItem
        },
        data() {
            return {
                accordionsData: [
                    {
                        label: "what are fvck_avatars?",
                        content: `<p>FVCK_AVATAR is a profile picture project at its core, but the avatars were actually designed to be used as landmarks in metaverse platforms, including LVCIDIA.</p><p>Also, since it’s being created by digital artist FVCKRENDER, the avatars give you access to the whole ecosystem he’s building.</p>`
                    },
                    {
                        label: "will fvck_avatars have utilities?",
                        content: `<p>FVCK_AVATAR's first utility is simply looking stunning. There are not a lot of 3D PFP projects out there and owning one will be a big flex.</p>
                        <p>The second utility is being part of the FVCKRENDER ecosystem, which includes discounts on future drops and being allowed in IRL events.</p>
                        <p>Thirdly, with the upcoming launch of the LVCIDIA experience, FVCK_AVATAR will provide an important utility linked with the tokenomics of the game.</p>
                        <p>Finally, one of the utilities down the road will include playable characters. Stay tuned for that!</p>
                        `
                    },
                    {
                        label: "What is LVCIDIA?",
                        content: `<p>LVCIDIA is a metaverse gaming platform with built-in tokenomics and high-level gameplay mechanics. It is an exploratory art experience taking place in a surreal sci-fi world and living on the blockchain.</p><p>To learn more, visit our <a href="https://roadmap.lvcidia.xyz" target="_blank" rel="noopener">roadmap</a>.</p>`
                    },
                    {
                        label: "how do i get a fvck_avatar?",
                        content: `<p>Simply by owning an eligible <a href="https://docs.google.com/spreadsheets/d/11OVLW6YLI84O20uXqtyENfkGW-RADBpZQH40gM2eLMY/" target="_blank" rel="noopener">FVCKRENDER NFT</a>, you’ll be able to take part in the pre-sale phases 1 and 2. This ensures that you’ll get an ESSENCE// which is the token needed to obtain your avatar.</p>
                        <p>If you don’t own an eligible FVCKRENDER NFT, there will be a public mint, but only if there are any avatars left. </p>
                        <p>After that, you’ll have to buy from a secondary market like LooksRare or Opensea. </p>
                        `
                    },
                    {
                        label: "what is essence// ?",
                        content: `<p>It is the redeem token necessary to mint your FVCK_AVATAR. You’ll need to burn 1 ESSENCE// to obtain 1 FVCK_AVATAR.</p>
                        <p>There is no time limit to burn your ESSENCE// and redeem your FVCK_AVATAR. But there will be no other utility to ESSENCE// other than claiming your FVCK_AVATAR.</p>
                        <p>FVCK_AVATARS will reveal automatically once the ESSENCE// is burned. </p>
                        `
                    },
                    {
                        label: "how will the snapshot work?",
                        content: `<p class="c-wysiwyg_warning">The snapshot has now been taken. Any purchases after this point will not count towards our allowlist! <br>Time of the snapshot: Sunday, March 20, 2022 3:00 PM EST</p><p>We will take a snapshot of all the wallets holding eligible NFTs for the minting of ESSENCE//. The date of the snapshot will not be communicated, so a strong word of advice for those who own FVCKRENDER’s NFTs on Nifty Gateway : Move your NFTs in your MetaMask wallet as soon as possible.</p>
                        <p>With that being said, Once the snapshot has happened, we will make an announcement so no one buys an NFT thinking they’ll be able to mint their ESSENCE// without being able to do it.</p>
                        <p>Also, your FVCKRENDER NFTs don’t need to be in your wallet when minting ESSENCE// because the smart contract will verify the addresses from the snapshot and not the wallets' content. </p>
                        `
                    },
                    {
                        label: "what fvckrender nfts are included in phases 1 and 2?",
                        content: `<p>Every eligible NFT & wallet address are available publicly in this <a href="https://docs.google.com/spreadsheets/d/11OVLW6YLI84O20uXqtyENfkGW-RADBpZQH40gM2eLMY/" target="_blank" rel="noopener">document</a>.</p>`
                    },
                    {
                        label: "will I be ok with my ledger or my trezor for the minting?",
                        content: `<p>Yes you will be ok. You should not encounter any problems.</p>`
                    },
                    {
                        label: "do we have commercial rights to the avatars we own?",
                        content: `<p>You own pretty much all the commercial rights to your FVCK_AVATAR and you can do what you want with it, as long as it doesn’t promote racist, sexist or anti-LGBTQ discourses. If you’re unsure what you can or can’t do, send us a message at lvcidia@gmail.com</p>`
                    },
                    {
                        label: "where is the nft metadata stored?",
                        content: `<p>During the sale, the metadata is hosted by a manifold.xyz collectible server. This is necessary in order to perform randomized distribution of avatars. Once the sale is over, all the metadata is finalized and posted to arweave.</p>`
                    },
                    {
                        label: "what are the royalties on secondary sales?",
                        content: `<p>6.9%</p>`
                    },
                ]
            }
        },
        props: {}
    }
</script>