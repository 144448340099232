<template>
    <div class="c-hero" :class="{'is-inview' :isInview}" ref="hero">
        <div class="c-hero_inner">
            <div class="o-container || u-relative || c-hero_container">
                <Grid :modifiers="['-col-4', '-col-12@from-medium', '-gutter']">
                    <GridItem
                        :col-start="1"
                        :col-end="2"
                        :col-start-medium="4"
                        :col-end-medium="13"
                        :class="['u-grid-medium']"
                    >
                        <h1 class="c-hero_title">
                            <span class="u-screen-reader-text">FVCK_AVATAR//</span>
                            <Logo classes="-big"></Logo>
                        </h1>
                    </GridItem>
                </Grid>

                <Grid :modifiers="['-col-4', '-col-12@from-medium', '-gutter']">
                    <GridItem
                        :col-start="2"
                        :col-end="4"
                        :col-start-medium="8"
                        :col-end-medium="12"
                        :col-start-big="10"
                        :col-end-big="12"
                    >
                        <div class="c-hero_content">
                            <Wysiwyg :modifiers="['-indent', '-stacked']">
                                <p>A collection of 12969 unique avatars made by FVCKRENDER that live on the Ethereum blockchain.</p>
                            </Wysiwyg>
                        </div>
                    </GridItem>
                </Grid>

                <div class="c-hero_info">
                    <Grid :modifiers="['-col-4', '-col-12@from-medium', '-gutter']">
                        <GridItem
                            :col-start="2"
                            :col-end="4"
                            :col-start-medium="8"
                            :col-end-medium="12"
                            :col-start-big="10"
                            :col-end-big="12"
                        >
                            <Wysiwyg>
                                <p>
                                    Dates : March 23rd - April 6th <br>
                                    Supply : 12969 <br>
                                    Price : 0.469 ETH <br>
                                    Sold out
                                </p>
                            </Wysiwyg>
                        </GridItem>
                    </Grid>
                </div>

                <div class="c-hero_scroll">
                    <div class="c-hero_scroll_button">
                        <span class="u-screen-reader-text">Scroll down</span>
                        <span class="c-hero_scroll_button_inner">
                            <svg class="c-hero_scroll_button_arrow" version="1.1" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                 viewBox="0 0 48 47" style="enable-background:new 0 0 48 47;" xml:space="preserve">
                                <path vector-effect="non-scaling-stroke" d="M0.8,23l23.5,23.5 M24.2,46.5v-46 M47.2,22.5l-23,24"/>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="c-hero_bottom || o-container" id="scroll">

        </div>
    </div>

    <video class="c-video-background" src="assets/videos/avatarteaser-optimized-2.mp4" muted ref="video" loop playsinline="true"></video>
</template>

<script>
    import { mapState } from "vuex";
    import Splitting from "splitting"

    import Grid from './Grid/Grid.vue'
    import GridItem from './Grid/GridItem.vue'
    import Wysiwyg from './Wysiwyg.vue';
    import Logo from './Logo.vue';

    export default {
        name: "Hero",
        components: {
            "Grid": Grid,
            "GridItem": GridItem,
            "Wysiwyg": Wysiwyg,
            "Logo": Logo
        },
        props: {},

        data() {
            return{
                isInview: false
            }
        },

        watch: {
            isFontsLoaded: "onFontsLoaded",
        },

        computed: {
            ...mapState("main", ['isFontsLoaded', 'totalSupply']),
        },

        mounted() {
            if (this.isFontsLoaded) {
                this.onFontsLoaded();
            }

            const $video = this.$refs.video

            // Set observer options
            const observerOptions = {
                rootMargin: "-1px -1px -1px -1px",
            };

            // Set observer callback
            const onIntersect = (entries) => {
                entries.forEach((entry) => {
                    // Then apply view methods
                    if (entry.intersectionRatio > 0) {
                        //inview
                        if($video.paused) {
                            $video.play()
                            this.isInview = true
                        }
                    } else if (!this.isFirstObserve) {
                        // Out of view
                        if(!$video.paused) {
                            $video.pause()
                            this.isInview = false
                        }
                    }
                });

                this.isFirstObserve = false;
            };

            //Create a single IntersectionObserver(IO) that watch hero element
            this.observer = new IntersectionObserver(onIntersect, observerOptions);
            this.observer.observe(this.$refs.hero);
        },

        beforeUnmount() {
            this.observer?.disconnect();
        },

        methods: {
            onFontsLoaded: function() {
                this.split()
            },

            split() {
                const splitting = Splitting({ target: '[data-splitting-lines]', by: 'lines' });

                requestAnimationFrame(() => {
                    if (!splitting.length) return

                    let wordIndex = 0
                    const $words = splitting[0].words

                    while (wordIndex < $words.length) {
                        const $word = $words[wordIndex]
                        const content = $word.innerHTML;
                        const wrapper = `<span class="word_inner">${content}</span>`
                        $word.innerHTML = wrapper
                        wordIndex++
                    }
                })
            }
        }
    }
</script>
