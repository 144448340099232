<template>
    <vue-logo :isVisible="isSideLogoVisible" />
    <Hero />
    <Intro />
    <Phases />
    <Handmade />
    <FAQ />
    <Footer />
</template>

<script>

import { mapActions } from 'vuex';

import Hero from '../components/Hero.vue';
import Intro from '../components/Intro.vue';
import Phases from '../components/Phases.vue';
import Handmade from '../components/Handmade.vue';
import FAQ from '../components/FAQ.vue';
import Logo from '../components/Logo.vue';
import Footer from '../components/Footer.vue';

export default {
    name: 'Home',

    components: {
        "Hero": Hero,
        "Intro": Intro,
        "Phases": Phases,
        "Handmade": Handmade,
        "FAQ": FAQ,
        "vue-logo": Logo,
        "Footer": Footer
    },

    data() {
        return {
            isSideLogoVisible: false
        }
    },

    created() {
        this.setDataLoaded(true)
    },

    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },

    beforeUnmount () {
        window.removeEventListener('scroll', this.onScroll)
    },

    methods: {
        ...mapActions('main', ['setDataLoaded']),

        onScroll () {
            // Get the current scroll position
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
            // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
            if (currentScrollPosition < 0) {
                return
            }
            
            //Toggle logo
            if( currentScrollPosition > (window.innerHeight / 2) ) {
                this.isSideLogoVisible = true
            } else {
                this.isSideLogoVisible = false
            }            
        }
    }
};
</script>