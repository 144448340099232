<template>
	<div class="c-accordion">
        <slot></slot>
	</div>
</template>

<script>
    export default {
        name: "Accordion",
        props: {}
    }
</script>