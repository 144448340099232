<template>
    <div class="o-container || o-padding -big-top -big-bottom">
        <Grid :modifiers="['-col-4', '-col-12@from-medium', '-gutter']">
            <!-- Not authenticated -->
            <GridItem
                v-if="!isAuthenticated"
                :col-start-medium="4"
                :col-end-medium="8"
            >
                <div class="o-padding -big-top">
                    <Wysiwyg :modifiers="['-outline']">
                        <p>Please authenticate by connecting a wallet.</p>
                    </Wysiwyg>
                </div>
            </GridItem>
            <!-- ./Not authenticated -->

            <!-- Authenticated -->
            <GridItem
                :col-start-medium="4"
                :col-end-medium="13"
                v-else
            >
                <template v-if="balanceOfEssenceTokens > 0">
                    <Label tag="h2"> Select at least one (1) ESSENCE// for redemption</Label>

                    <Wysiwyg class="o-padding -tiny-bottom">
                        <p>ESSENCE// can be burned to redeem a FVCK_AVATAR//</p>
                    </Wysiwyg>

                    <form v-on:submit.prevent="handleSubmit" class="c-form-redeem || o-padding">
                        <div class="c-form-redeem_header">
                            <button class="c-link" :class="{'is-active': this.allSelected}" type="button" v-on:click="selectAll">
                                select all <span v-if="balanceOfEssenceTokens > 0">({{balanceOfEssenceTokens}})</span>
                            </button>
                        </div>

                        <div class="c-form-redeem_container">
                            <div class="c-form-redeem_item" v-for="index in balanceOfEssenceTokens" v-bind:key="index">
                                <input
                                    class="c-form-redeem_checkbox"
                                    type="checkbox"
                                    name="essenceSelected"
                                    :id="`essence-${index}`"
                                    :value="`essence-${index}`"
                                    v-model="formData.essenceSelected"
                                >
                                <label class="c-form-redeem_checkboxLabel" :for="`essence-${index}`">
                                    <span class="u-screen-reader-text">Check</span>
                                </label>

                                <img class="c-form-redeem_img" src="assets/images/essence.jpg" alt="ESSENCE">
                            </div>
                        </div>

                        <div class="c-form-redeem_footer" v-if="balanceOfEssenceTokens > 0">
                            <Wysiwyg>
                                <div class="c-form-redeem_footer_text">
                                    <div class="c-form_item || c-form-redeem_footer_checkbox" v-if="isRedemptionEnabled">
                                        <input
                                            class="c-form_checkbox"
                                            type="checkbox"
                                            :value="formData.confirm"
                                            v-model="formData.confirm"
                                            id="confirm"
                                        >

                                        <label for="confirm" class="c-form_checkboxLabel">
                                            I understand that this action is irreversible.
                                        </label>
                                    </div>

                                    <p>
                                        <span class="u-mono || c-form-redeem_footer_text_label">essence(s) selected:</span>  <span class="c-form-redeem_footer_number">{{formData.essenceSelected.length}}</span>
                                    </p>
                                </div>
                            </Wysiwyg>

                            <div class="c-form-redeem_footer_cta">

                                <button type="submit" class="c-button || c-form-redeem_button" :disabled="!isRedemptionEnabled || isRedeeming || formData.essenceSelected.length <= 0 || !formData.confirm">
                                    <span class="c-button_label" v-if="!isRedemptionEnabled">Redeeming disabled</span>
                                    <span class="c-button_label" v-else-if="isRedeeming && isTxConfirmed">Successful transaction, unveiling your avatar...</span>
                                    <span class="c-button_label" v-else-if="isRedeeming">Waiting for confirmation...</span>
                                    <span class="c-button_label" v-else>Burn & redeem ({{formData.essenceSelected.length}})</span>
                                </button>

                                <Spinner class="c-form-redeem_footer_spinner" v-if="isRedeeming" :isSquare="true"></Spinner>
                            </div>

                            <Wysiwyg v-if="!isRedemptionEnabled">
                                <p>Redemption phase starts at 5:00 PM EST on April 6th.</p>
                                <p class="c-wysiwyg_warning">The redemption phase is not enabled.</p>
                            </Wysiwyg>

                            <Wysiwyg v-if="redemptionErrorMessage">
                                <p class="c-wysiwyg_warning">Error: {{redemptionErrorMessage}}</p>
                            </Wysiwyg>
                        </div>
                    </form>
                </template>

                <!-- No essence feedback -->
                <template v-else>
                    <Wysiwyg class="o-padding -tiny-bottom">
                        <p class="c-wysiwyg_warning">You don't own any eligible token(s) for redemption.</p>
                        <p>You can buy an ESSENCE// on the secondary market. <br>ESSENCE// can be burned to redeem a FVCK_AVATAR//</p>
                    </Wysiwyg>

                    <ul class="c-socials_list">
                        <li class="c-socials_list_item">
                            <app-button
                                tag="a"
                                label="Buy on looksrare"
                                url="https://looksrare.org/collections/0xDDB149AE8E6635Df01a530da1E46921Bd78Dc385"
                                :isTargetBlank="true"
                            />
                        </li>

                        <li class="c-socials_list_item">
                            <app-button
                                tag="a"
                                label="Buy on opensea"
                                url="https://opensea.io/collection/fvck-avatar-essence"
                                :isTargetBlank="true"
                            />
                        </li>
                    </ul>
                </template>
                <!-- ./No essence feedback -->
            </GridItem>
            <!-- ./Authenticated -->
        </Grid>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  FvckAvatarRedeemer,
  /** This error should be handled gracefully - not really an error as people are expected to do this */
  UserRejectedTransactionError,
  /** Should handle any transaction failures */
  TransactionFailedError,
  /**
   * Other errors to potentially handle, or just can handle them all the same
   *  - InvalidNumAvatarsToMint,
   *  - InsufficientEssenceBalance,
   *  - UserNotConnectedError,
   *  - InvalidNetworkError
   */
} from '../vendor/fvckavatar-redeemer'

import Grid from '../components/Grid/Grid.vue'
import GridItem from '../components/Grid/GridItem.vue'
import Spinner from '../components/Spinner.vue'
import Wysiwyg from '../components/Wysiwyg.vue'
import Label from '../components/Label.vue';
import AppButton from '../components/AppButton.vue';

export default {
    name: "Redeem",

    components: {
        "Grid": Grid,
        "GridItem": GridItem,
        "Wysiwyg": Wysiwyg,
        "Label": Label,
        "Spinner": Spinner,
        "app-button": AppButton
    },

    async created() {
        await this.initializeRedeemer();
        this.setDataLoaded(true)
    },

    computed: {
        ...mapState('user', [
            'isAuthenticated',
            'connectedAddress',
            'clientToken',
            'balanceOfEssenceTokens',
        ]),
        ...mapState('redeem', [
            'isRedeeming',
            'didRedeem',
            'isTxConfirmed',
            'txEtherscanUrl',
            'redemptionErrorMessage',
            'redeemedTokens',
            'isRedemptionEnabled',
        ]),
    },
    watch: {
        isAuthenticated(newIsAuthenticated, oldIsAuthenticated) {
            if (newIsAuthenticated && !oldIsAuthenticated) {
                this.initializeRedeemer()
            }
        }
    },

    data() {
        return {
            contractAddress: process.env.VUE_APP_MANIFOLD_ESSENCE_CONTRACT_ADDRESS,
            fallbackProvider: process.env.VUE_APP_MANIFOLD_FALLBACK_PROVIDER,
            network: process.env.VUE_APP_MANIFOLD_NETWORK,
            formData: {
                essenceSelected: [],
                confirm: false
            },
            allSelected: false
        }
    },

    mounted() {
    },

    methods: {
        ...mapActions('main', [
            'setDataLoaded',
        ]),
        ...mapActions('redeem', [
            'setIsRedeeming',
            'setDidRedeem',
            'setIsTxConfirmed',
            'setTxEtherscanUrl',
            'setRedeemedTokens',
            'setRedemptionErrorMessage',
            'setIsRedemptionEnabled'
        ]),

        ...mapActions('user', [
            'setUserBalanceOfEssenceTokens',
        ]),

        handleSubmit(event) {
            event.preventDefault;

            this.clearStates()
            this.redeem()
        },

        selectAll() {
            if (!this.allSelected) {
                const selected = [];

                for (let index = 1; index < this.balanceOfEssenceTokens + 1; index++) {
                    selected.push(`essence-${index}`);
                }

                this.formData.essenceSelected = selected;
                this.allSelected = true;
            } else {
                this.formData.essenceSelected = [];
                this.allSelected = false;
            }
        },

        async initializeRedeemer() {
            const essenceContractAddress = process.env.VUE_APP_MANIFOLD_ESSENCE_CONTRACT_ADDRESS;
            const avatarContractAddress = process.env.VUE_APP_MANIFOLD_AVATAR_CONTRACT_ADDRESS;
            const network = parseInt(process.env.VUE_APP_MANIFOLD_NETWORK)


            this.redeemer = new FvckAvatarRedeemer(
                // The Essence contract address (nft(s) being burned)
                essenceContractAddress,
                // The Avatar contract address (nft(s) being minted)
                avatarContractAddress,
                // The network the request should be made against
                network,
                // the fallback network (infura)
                process.env.VUE_APP_MANIFOLD_FALLBACK_PROVIDER
            )

            if (avatarContractAddress) {
                const isRedemptionEnabled = await this.redeemer.isRedemptionEnabled()
                this.setIsRedemptionEnabled(isRedemptionEnabled)
            }

            if (this.isAuthenticated) {
                const balanceOfEssenceTokens = await this.redeemer.getBalanceOfEssenceTokens()
                this.setUserBalanceOfEssenceTokens(balanceOfEssenceTokens)
            }
        },

        async redeem() {
            this.setIsRedeeming(true)

            try {
                /**
                 * This will be called when the transaction has been completed, but before the avatars
                 * have been revealed.
                 */
                this.redeemer.onTxConfirmed((txEtherscanUrl, /*transactionHash*/) => {
                    this.setTxEtherscanUrl(txEtherscanUrl)
                    this.setIsTxConfirmed(true)
                })

                const numTokensToRedeem = this.formData.essenceSelected.length
                /**
                 * type Token {
                 *   name: string,
                 *   image_url: string,
                 *   tokenAddress: string,
                 *   tokenId: number
                 * }
                 */
                const avatars = await this.redeemer.redeem(numTokensToRedeem) ;

                /**
                 * Success! Tokens have been revealed
                 */
                this.setRedeemedTokens(avatars)
                this.setDidRedeem(true)
                this.setUserBalanceOfEssenceTokens(this.balanceOfEssenceTokens - numTokensToRedeem)

                /**
                 * Redirect on inventory page
                 */
                this.$router.push('inventory');
            } catch (e)  {
                if (e instanceof UserRejectedTransactionError) {
                    // handle user rejected transaction
                } else if (e instanceof TransactionFailedError) {
                    // handle transaction failed error
                }

                this.setRedemptionErrorMessage(e.error ? e.error.message : e.message)
            }

            this.setIsRedeeming(false)
        },

        clearStates() {
            this.setIsRedeeming(false)
            this.setIsTxConfirmed(false)
            this.setRedemptionErrorMessage(null)
        }
    }
};
</script>
