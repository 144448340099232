const actions = {
    setFontsLoaded: (context, val) => {
        context.commit("SET_FONTS_LOADED", val);
    },
    setDataLoaded: (context, val) => {
        context.commit("SET_DATA_LOADED", val);
    },
    setIsSoldOut: (context, val) => {
        context.commit("SET_IS_SOLD_OUT", val);
    },
    setSupplySold: (context, val) => {
        context.commit("SET_SUPPLY_SOLD", val);
    },
    setCurrentSalePhase: (context, val) => {
        context.commit("SET_CURRENT_SALE_PHASE", val);
    },
    setHasSaleEnded: (context, val) => {
        context.commit("SET_HAS_SALE_ENDED", val);
    },
    setTokensRemaining: (context, val) => {
        context.commit("SET_TOKENS_REMAINING", val);
    },
    setTotalSupply: (context, val) => {
        context.commit("SET_TOTAL_SUPPLY", val);
    },
    setPresalePriceEth: (context, val) => {
        context.commit("SET_PRESALE_PRICE_ETH", val);
    },
    setPublicSalePriceEth: (context, val) => {
        context.commit("SET_PUBLIC_SALE_PRICE_ETH", val);
    },
    setUserClaimAmount: (context, val) => {
        context.commit("SET_USER_CLAIM_AMOUNT", val);
    },
    setHasUserAlreadyClaimed: (context, val) => {
        context.commit("SET_HAS_USER_ALREADY_CLAIMED", val);
    },
    setPresalePurchaseLimit: (context, val) => {
        context.commit("SET_PRESALE_PURCHASE_LIMIT", val);
    },
    setHasUserAlreadyUsedPresalePurchase: (context, val) => {
        context.commit("SET_HAS_USER_ALREADY_USED_PRESALE_PURCHASE", val);
    },
    setNumTokensUserHasMinted: (context, val) => {
        context.commit("SET_NUM_TOKENS_USER_HAS_MINTED", val);
    },
    setIsCollectibleWidgetLoaded: (context, val) => {
        context.commit("SET_IS_COLLECTIBLE_WIDGET_LOADED", val);
    },
    setTransactionLimit: (context, val) => {
        context.commit("SET_TRANSACTION_LIMIT", val);
    },
};

export default actions;
