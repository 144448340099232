<template>
    <div class="u-background">
        <div class="o-container">
            <Grid :modifiers="['-col-4', '-col-12@from-medium', '-gutter']">
                <GridItem 
                    :col-start-medium="4"
                    :col-end-medium="10"
                >
                    <Wysiwyg :modifiers="['-indent', '-stacked', '-big']">  
                        <p>Not your typical PFP project, Fvck_Avatars are 3D collectibles that have inherent utilities in the ecosystem Fvckrender is building.</p>
                    </Wysiwyg>
                </GridItem>     
            </Grid>

            <Grid :modifiers="['-col-4', '-col-12@from-medium', '-gutter']" class="o-padding -medium-top">
                <GridItem 
                    :col-start-medium="4"
                    :col-end-medium="7"
                >
                    <Wysiwyg :modifiers="['-indent']">  
                        <p>Encapsulated in this project are the elements developed by FVCKRENDER over the last 10 years in his work. With the addition of new elements, the avatars have been designed to coexist with the FVCK_CRYSTALS and act as beacons in LVCIDIA.</p>
                    </Wysiwyg>
                </GridItem> 

                <GridItem 
                    :col-start-medium="9"
                    :col-end-medium="13"
                >
                    <div class="c-gallery -half">
                        <div class="c-gallery_item" v-for="(item, index) in items" :key="index" data-lazy-load-wrapper>
                            <div class="c-gallery_item_inner -square">
                                <img class="c-gallery_item_image" :data-src="item.url" alt="FVCK_AVATAR" data-lazy-load>
                            </div>
                        </div>
                    </div>
                </GridItem> 
            </Grid>
        </div>    
    </div>
</template>

<script>
    import { lazyLoadImage } from "../utils/image";
    
    import Grid from './Grid/Grid.vue';
    import GridItem from './Grid/GridItem.vue';
    import Wysiwyg from './Wysiwyg';

    export default {
        name: "Intro",
        props: {},
        components: {
            "Grid": Grid,
            "GridItem": GridItem,
            "Wysiwyg": Wysiwyg
        },

        data() {
            return {
                items: [
                    {url: 'assets/images/avatar_01.jpg'},
                    {url: 'assets/images/avatar_02.jpg'},
                    {url: 'assets/images/avatar_03.jpg'},
                    {url: 'assets/images/avatar_04.jpg'}
                ]
            }
        },

        mounted() {  
            const lazyLoadItems = Array.from(this.$el.querySelectorAll('[data-lazy-load]'))

            // Set observer options
            const observerOptions = {
                rootMargin: "-1px -1px -1px -1px",
            };

            // Set observer callback
            const onIntersect = (entries) => {
                entries.forEach((entry) => {
                    // Then apply view methods
                    if (entry.intersectionRatio > 0) {
                        // Inview
                        lazyLoadImage(entry.target)

                        this.observer.unobserve(entry.target)                        
                    } else if (!this.isFirstObserve) {
                        // Out of view
                    }
                });

                this.isFirstObserve = false;
            };

            //Create a single IntersectionObserver(IO) that watches all elements
            this.observer = new IntersectionObserver(onIntersect, observerOptions);

            // Observe each elements
            let index = 0;
            while (index < lazyLoadItems.length) {
                const $view = lazyLoadItems[index];
                
                this.observer.observe($view);
                index++;
            }
        },

        beforeUnmount() {
            this.observer?.disconnect();
        }
    }
</script>