const modifersMixin = {
    props: ['modifiers'],
    methods: {
        getModifiers() {
            if (typeof this.modifiers === 'undefined') {
                return false
            } else if (typeof this.modifiers == 'object') {
                return this.modifiers.map(item => `-${item}`)
            } else {
                return `-${this.modifiers}`;
            }
        }
    }
}

export default modifersMixin;