<template>
    <div 
        class="c-wysiwyg"
        :class="modifiers"
        v-if="content"
        v-html="content"
    >
    
    </div>

    <div 
        class="c-wysiwyg"
        :class="modifiers"
        v-else
    >
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Wysiwyg",
        props: {
            content: { 
                type: String
            }
        }
    }
</script>