import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  isFontsLoaded: false,
  isDataLoaded: false,
  isCollectibleWidgetLoaded: false,
  totalSupply: null,
  isSoldOut: true,
  supplySold: 12969,
  // 'loading' | 'deactivated' | 'expired' | 'to-claim' | 'claim' | 'to-presale' | 'to-public-sale' | 'presale' | 'public-sale' | null
  currentSalePhase: null,
  hasSaleEnded: false,
  tokensRemaining: null,
  presalePriceEth: null,
  publicSalePriceEth: null,
  // If `null`, it implies we have not fetched this data yet.
  userClaimAmount: null,
  hasUserAlreadyClaimed: false,
  // If `null`, it implies we have not fetched this data yet.
  presalePurchaseLimit: null,
  hasUserAlreadyUsedPresalePurchase: false,
  numTokensUserHasMinted: null,
  transactionLimit: null
};

const store = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}

export default store;
