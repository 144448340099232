const mutations = {
    SET_USER_AUTHENTICATED: (state, val) => {
        state.isAuthenticated = val;
    },

    SET_USER_ADDRESS: (state, val) => {
        state.connectedAddress = val;
    },

    SET_USER_BALANCE_OF_ESSENCE_TOKENS: (state, val) => {
        state.balanceOfEssenceTokens = val;
    },

    SET_USER_AVATAR_TOKENS: (state, val) => {
        state.avatarTokens = val;
    },
};

export default mutations;
