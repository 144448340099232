<template>
	<article class="c-accordion_item">
        <header tabindex="0" role="button" class="c-accordion_header" aria-expanded="false" ref="toggler" @click="toggle">
            <h3 class="c-accordion_title">
                {{ label }}
            </h3>
            
            <span class="c-accordion_button"></span>
        </header>

        <div class="c-accordion_inner" data-accordion="container" ref="container">
            <div class="c-accordion_content" data-accordion="content" ref="content">
                <slot></slot>
            </div>
        </div>
	</article>
</template>

<script>
    export default {
        name: "AccordionItem",
        props: {
            label: {
                type: String,
                default: null
            }
        },

        data() {
            return {
                isOpen: false,
                duration: 0
            }
        },

        mounted() {
            // Add window event
            this.resizeBind = this.resize.bind(this)
            window.addEventListener('resize', this.resizeBind)

            this.resize()
        },

        beforeUnmount() {
            window.removeEventListener('resize', this.resizeBind)
        },

        methods: {
            toggle: function() {               

                if(this.isOpen) {
                    this.close()
                } else {
                    this.open()
                }
            },

            open: function() {
                this.$el.classList.add('is-open')
                this.isOpen = true
                this.$refs.toggler.setAttribute('aria-expanded', 'true')

                // Clear close callback
                if (this.closeTimeout) {
                    clearTimeout(this.closeTimeout);
                }

                // Set open callback
                this.openTimeout = setTimeout(() => {

                }, this.duration)
            },

            close: function() {
                this.$el.classList.remove('is-open')
                this.isOpen = false
                this.$refs.toggler.setAttribute('aria-expanded', 'false')

                // Clear open callback
                if (this.openTimeout) {
                    clearTimeout(this.openTimeout);
                }

                // Set close callback
                this.closeTimeout = setTimeout(() => {


                }, this.duration);
            },

            resize: function() {
                // Set content height
                const height = this.$refs.content.offsetHeight
                this.$el.style.setProperty('--accordion-container-height', `${height}px`)
                
                // Get styles
                const duration = window.getComputedStyle(this.$el).getPropertyValue('--accordion-duration');
                this.$refs.container.style.setProperty('--accordion-duration', duration);
                this.duration = 1000 * parseInt(duration, 10);
            }
        }
    }
</script>